@tailwind base;
@tailwind components;
@tailwind utilities;
*,
:after,
:before {
  border: 0 solid #e5e7eb;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:after,
:before {
  --tw-content: "";
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
body {
  line-height: inherit;
  margin: 0;
}
hr {
  border-top-width: 0.0625rem;
  color: inherit;
  height: 0;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
}
fieldset,
legend {
  padding: 0;
}
menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #9ca3af;
  opacity: 1;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #9ca3af;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #9ca3af;
  opacity: 1;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #9ca3af;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #9ca3af;
  opacity: 1;
}
[role="button"],
button {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
img,
video {
  height: auto;
  max-width: 100%;
}
[hidden] {
  display: none;
}
[multiple],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  /* border-color: #414141; */
  border-radius: 0;
  border-width: 0.0625rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 0.75rem;
}
[multiple]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
select:focus,
textarea:focus {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color: #2563eb;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #414141;
  opacity: 1;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #414141;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #414141;
  opacity: 1;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #414141;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #414141;
  opacity: 1;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
}
select {
  -webkit-print-color-adjust: exact;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23414141' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  color-adjust: exact;
  padding-right: 2.5rem;
}
[multiple] {
  -webkit-print-color-adjust: unset;
  background-image: none;
  background-position: 0 0;
  background-repeat: unset;
  background-size: initial;
  color-adjust: unset;
  padding-right: 0.75rem;
}
[type="checkbox"],
[type="radio"] {
  -webkit-print-color-adjust: exact;
  -ms-flex-negative: 0;
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  /* border-color: #D1D5DB; */
  border-width: 0.0625rem;
  border-radius: 4px;
  color: #018786;
  color-adjust: exact;
  display: inline-block;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 1rem;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 1rem;
}
[type="checkbox"] {
  border-radius: 4px;
}
[type="radio"] {
  border-radius: 100%;
}
[type="checkbox"]:focus,
[type="radio"]:focus {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0.125rem;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}
[type="checkbox"]:checked,
[type="radio"]:checked {
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
}
[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}
[type="radio"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}
[type="checkbox"]:checked:focus,
[type="checkbox"]:checked:hover,
[type="radio"]:checked:focus,
[type="radio"]:checked:hover {
  background-color: currentColor;
  border-color: transparent;
}
[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
}
[type="checkbox"]:indeterminate:focus,
[type="checkbox"]:indeterminate:hover {
  background-color: currentColor;
  border-color: transparent;
}
[type="file"] {
  background: unset;
  border-color: inherit;
  border-radius: 0;
  border-width: 0;
  font-size: unset;
  line-height: inherit;
  padding: 0;
}
[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}
html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[x-cloak] {
  display: none !important;
}
.foundation-mq {
  font-family: "tiny=0&sm=640px&md=768px&lg=1024px&xl=1280px";
}
*,
:after,
:before {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
}
@media (min-width: 40rem) {
  .container {
    max-width: 40rem;
  }
}
@media (min-width: 48rem) {
  .container {
    max-width: 48rem;
  }
}
@media (min-width: 64rem) {
  .container {
    max-width: 64rem;
  }
}
@media (min-width: 80rem) {
  .container {
    max-width: 80rem;
  }
}
@media (min-width: 96rem) {
  .container {
    max-width: 96rem;
  }
}
.button {
  --tw-gradient-from: #00d8a4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 216, 164, 0));
  --tw-gradient-to: #00b497;
  --tw-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 4px 4px var(--tw-shadow-color);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--tw-gradient-stops))
  );
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  place-content: center;
  place-items: center;
}
.button-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: 9999px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
  justify-content: center;
  position: absolute;
  right: 20px;
}
.sr-only {
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.0625rem;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.top-24 {
  top: 5.8rem;
}
.left-1\/2 {
  left: 50%;
}
.top-4 {
  top: 1rem;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.top-2 {
  top: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
.right-4 {
  right: 1rem;
}
.top-16 {
  top: 4rem;
}
.-top-5 {
  top: -1.25rem;
}
.bottom-10vh {
  bottom: 10vh;
}
.z-50 {
  z-index: 50;
}
.-z-10 {
  z-index: -10;
}
.z-10 {
  z-index: 10;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.row-start-1 {
  grid-row-start: 1;
}
.\!m-0 {
  margin: 0 !important;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-6 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.my-5vh {
  margin-bottom: 5vh;
  margin-top: 5vh;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.-my-10 {
  margin-bottom: -2.5rem;
  margin-top: -2.5rem;
}
.my-5 {
  margin-top: 1.25rem;
}
.mb-5,
.my-5 {
  margin-bottom: 1.25rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.-mt-\[10\%\] {
  margin-top: -10%;
}
.mt-5vh {
  margin-top: 5vh;
}
.-mt-\[20vh\] {
  margin-top: -20vh;
}
.-mt-14 {
  margin-top: -3.5rem;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.aspect-video {
  aspect-ratio: 16/9;
}
.h-screen {
  height: 100vh;
}
.h-6 {
  height: 1.5rem;
}
.h-10 {
  height: 2.5rem;
}
.h-4 {
  height: 1rem;
}
.h-full {
  height: 100%;
}
.h-5 {
  height: 1.25rem;
}
.h-24 {
  height: 6rem;
}
.h-\[615px\] {
  height: 38.4375rem;
}
.min-h-\[400px\] {
  min-height: 25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-4 {
  width: 1rem;
}
.w-full {
  width: 100%;
}
.w-10 {
  width: 2.5rem;
}
.w-5 {
  width: 1.25rem;
}
.w-14 {
  width: 3.5rem;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-\[400px\] {
  width: 25rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-\[500px\] {
  max-width: 31.25rem;
}
.max-w-lg {
  max-width: 32rem;
}
.max-w-sm {
  max-width: 24rem;
}
.flex-1 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}
.-translate-x-1\/2,
.rotate-180 {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
  --tw-rotate: 180deg;
}
.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}
.scale-125,
.scale-150 {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}
.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.scroll-m-48 {
  scroll-margin: 12rem;
}
.scroll-m-10vh {
  scroll-margin: 10vh;
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.place-items-center {
  place-items: center;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.gap-4 {
  gap: 1rem;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-10 {
  gap: 2.5rem;
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(1rem * var(--tw-space-x-reverse));
}
.space-y-5vh > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(5vh * var(--tw-space-y-reverse));
  margin-top: calc(5vh * (1 - var(--tw-space-y-reverse)));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
}
.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-left-width: calc(0.0625rem * (1 - var(--tw-divide-x-reverse)));
  border-right-width: calc(0.0625rem * var(--tw-divide-x-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-bottom-width: calc(0.0625rem * var(--tw-divide-y-reverse));
  border-top-width: calc(0.0625rem * (1 - var(--tw-divide-y-reverse)));
}
.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}
.overflow-hidden {
  overflow: hidden;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.border-2 {
  border-width: 0.125rem;
}
.border {
  border-width: 0.0625rem;
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.\!border-green-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 166 126 / var(--tw-border-opacity)) !important;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(0 216 164 / var(--tw-border-opacity));
}
.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 166 126 / var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-aqua-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 252 250 / var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 216 164 / var(--tw-bg-opacity));
}
.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.bg-white\/80 {
  background-color: hsla(0, 0%, 100%, 0.8);
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}
.bg-gradient-to-br {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(var(--tw-gradient-stops))
  );
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.from-green-400 {
  --tw-gradient-from: #00d8a4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 216, 164, 0));
}
.from-green-50\/30 {
  --tw-gradient-from: rgba(240, 253, 244, 0.3);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(240, 253, 244, 0));
}
.from-yellow-50 {
  --tw-gradient-from: #fefce8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(55, 92%, 95%, 0));
}
.from-aqua-400 {
  --tw-gradient-from: #70d3bf;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(112, 211, 191, 0));
}
.from-yellow-100\/20 {
  --tw-gradient-from: hsla(55, 97%, 88%, 0.2);
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, hsla(55, 97%, 88%, 0));
}
.to-aqua-500 {
  --tw-gradient-to: #00b497;
}
.to-aqua-200\/40 {
  --tw-gradient-to: rgba(243, 252, 250, 0.4);
}
.to-aqua-200 {
  --tw-gradient-to: #f3fcfa;
}
.to-green-500 {
  --tw-gradient-to: #00a67e;
}
.to-green-500\/20 {
  --tw-gradient-to: rgba(0, 166, 126, 0.2);
}
.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}
.fill-current {
  fill: currentColor;
}
.stroke-\[3\] {
  stroke-width: 3;
}
.p-3 {
  padding: 0.75rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-4 {
  padding: 1rem;
}
.p-10 {
  padding: 2.5rem;
}
.py-5vh {
  padding-bottom: 5vh;
  padding-top: 5vh;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.py-2\.5 {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}
.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.py-3 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-1 {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.py-6 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.py-10vh {
  padding-bottom: 10vh;
  padding-top: 10vh;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-3\.5 {
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-5 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.pb-5vh {
  padding-bottom: 5vh;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pb-10vh {
  padding-bottom: 10vh;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.text-center {
  text-align: center;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.font-medium {
  font-weight: 500;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(65 65 65 / var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(0 166 126 / var(--tw-text-opacity));
}
.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(0 216 164 / var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-current {
  color: currentColor;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(28 28 28 / var(--tw-text-opacity));
}
.text-aqua-500 {
  --tw-text-opacity: 1;
  color: rgb(0 180 151 / var(--tw-text-opacity));
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}
.text-transparent {
  color: transparent;
}
.decoration-green-500 {
  -webkit-text-decoration-color: #00a67e;
  text-decoration-color: #00a67e;
}
.decoration-2 {
  text-decoration-thickness: 2px;
}
.accent-aqua-400 {
  accent-color: #70d3bf;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
}
.shadow-lg,
.shadow-sm {
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
}
.shadow {
  --tw-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 4px 4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.outline-2 {
  outline-width: 0.125rem;
}
.outline-green-500 {
  outline-color: #00a67e;
}
.outline-blue-600 {
  outline-color: #2563eb;
}
.ring-inset {
  --tw-ring-inset: inset;
}
.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 216 164 / var(--tw-ring-opacity));
}
.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
.transition-colors {
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: color, background-color, border-color, fill,
    stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, fill, stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-transform {
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration-300 {
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-in {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.before\:block:before {
  content: var(--tw-content);
  display: block;
}
.before\:h-\[20vh\]:before {
  content: var(--tw-content);
  height: 20vh;
}
.before\:h-20:before {
  content: var(--tw-content);
  height: 5rem;
}
.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}
.before\:bg-white:before {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  content: var(--tw-content);
}
.after\:relative:after {
  content: var(--tw-content);
  position: relative;
}
.after\:z-0:after {
  content: var(--tw-content);
  z-index: 0;
}
.after\:-mt-15vh:after {
  content: var(--tw-content);
  margin-top: -15vh;
}
.after\:block:after {
  content: var(--tw-content);
  display: block;
}
.after\:h-4:after {
  content: var(--tw-content);
  height: 1rem;
}
.after\:h-15vh:after {
  content: var(--tw-content);
  height: 15vh;
}
.after\:w-4:after {
  content: var(--tw-content);
  width: 1rem;
}
.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}
.after\:rounded-full:after {
  border-radius: 9999px;
  content: var(--tw-content);
}
.after\:bg-white:after {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  content: var(--tw-content);
}
.after\:shadow-md:after {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  content: var(--tw-content);
}
.after\:duration-300:after {
  content: var(--tw-content);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hover\:cursor-pointer:hover {
  cursor: pointer;
}
.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.focus\:border-transparent:focus {
  border-color: transparent;
}
.focus\:accent-aqua-400:focus {
  accent-color: #70d3bf;
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 216 164 / var(--tw-ring-opacity));
}
.focus\:ring-aqua-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(112 211 191 / var(--tw-ring-opacity));
}
@-webkit-keyframes bounce-small {
  0%,
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
}
@keyframes bounce-small {
  0%,
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
}
.group:hover .group-hover\:animate-bounce-small {
  -webkit-animation: bounce-small 1s infinite;
  animation: bounce-small 1s infinite;
}
.peer:checked ~ .peer-checked\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(0 216 164 / var(--tw-border-opacity));
}
.peer:checked ~ .peer-checked\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 216 164 / var(--tw-bg-opacity));
}
.peer:checked ~ .peer-checked\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(0 216 164 / var(--tw-text-opacity));
}
.peer:checked ~ .peer-checked\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.peer:checked ~ .peer-checked\:after\:translate-x-4:after {
  --tw-translate-x: 1rem;
  content: var(--tw-content);
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 40rem) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:max-w-screen-sm {
    max-width: 40rem;
  }
  .sm\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .sm\:items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .sm\:justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm\:text-left {
    text-align: left;
  }
}
@media (min-width: 48rem) {
  .md\:right-8 {
    right: 2rem;
  }
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .md\:block {
    display: block;
  }
  .md\:hidden {
    display: none;
  }
  .md\:h-12 {
    height: 3rem;
  }
  .md\:scroll-m-64 {
    scroll-margin: 16rem;
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .md\:gap-12 {
    gap: 3rem;
  }
  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
    margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
  }
  .md\:p-8 {
    padding: 2rem;
  }
  .md\:py-3 {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
  .md\:py-10vh {
    padding-bottom: 10vh;
    padding-top: 10vh;
  }
  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
}
@media (min-width: 64rem) {
  .lg\:-top-5vh {
    top: -5vh;
  }
  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .lg\:col-start-1 {
    grid-column-start: 1;
  }
  .lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:row-start-1 {
    grid-row-start: 1;
  }
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:block {
    display: block;
  }
  .lg\:flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .lg\:h-80 {
    height: 20rem;
  }
  .lg\:w-2\/3 {
    width: 66.666667%;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:max-w-screen-lg {
    max-width: 64rem;
  }
  .lg\:max-w-none {
    max-width: none;
  }
  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .lg\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .lg\:gap-16 {
    gap: 4rem;
  }
  .lg\:gap-14 {
    gap: 3.5rem;
  }
  .lg\:gap-\[5vw\] {
    gap: 5vw;
  }
  .lg\:p-16 {
    padding: 4rem;
  }
  .lg\:p-8 {
    padding: 2rem;
  }
  .lg\:p-14 {
    padding: 3.5rem;
  }
  .lg\:py-15vh {
    padding-bottom: 15vh;
    padding-top: 15vh;
  }
  .lg\:py-0 {
    padding-bottom: 0;
    padding-top: 0;
  }
  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .lg\:pt-0 {
    padding-top: 0;
  }
  .lg\:text-left {
    text-align: left;
  }
  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
@media (min-width: 80rem) {
  .xl\:top-0 {
    top: 0;
  }
  .xl\:right-0 {
    right: 0;
  }
  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xl\:col-start-2 {
    grid-column-start: 2;
  }
  .xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .xl\:row-start-1 {
    grid-row-start: 1;
  }
  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .xl\:mt-10vh {
    margin-top: 10vh;
  }
  .xl\:flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .xl\:max-w-screen-xl {
    max-width: 80rem;
  }
  .xl\:max-w-none {
    max-width: none;
  }
  .xl\:flex-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xl\:scale-125,
  .xl\:scale-150 {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:place-items-center {
    place-items: center;
  }
  .xl\:items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .xl\:gap-20 {
    gap: 5rem;
  }
  .xl\:gap-28 {
    gap: 7rem;
  }
  .xl\:p-11 {
    padding: 2.75rem;
  }
  .xl\:py-5 {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
  }
  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xl\:py-10vh {
    padding-bottom: 10vh;
    padding-top: 10vh;
  }
  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .xl\:py-12 {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
  .xl\:pr-20 {
    padding-right: 5rem;
  }
  .xl\:pb-15vh {
    padding-bottom: 15vh;
  }
  .xl\:text-left {
    text-align: left;
  }
  .xl\:text-right {
    text-align: right;
  }
  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .xl\:text-\[160px\] {
    font-size: 10rem;
  }
  .xl\:font-semibold {
    font-weight: 600;
  }
}
@media (min-width: 96rem) {
  .\32xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .\32xl\:py-15vh {
    padding-bottom: 15vh;
    padding-top: 15vh;
  }
  .\32xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

#pricing .col-headers {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: flex-end;
  column-gap: 2em;
}

/** {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    -webkit-box-sizing: border-box;*/
/*    -moz-box-sizing: border-box;*/
/*    box-sizing: border-box;*/
/*}*/

/*body {*/
/*    background: #2e2a2a;*/
/*    color: #FFF;*/
/*    font-size: 62.5%;*/
/*    font-family: 'Roboto', Arial, Helvetica, Sans-serif, Verdana;*/
/*}*/

.pricing-table > div {
  color: white;
  border-radius: 16px;
}
ul {
  list-style-type: none;
}

a {
  color: #00d8a4;
  text-decoration: none;
}

.pricing-table-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.6em;
  color: #fff;
  margin-top: 15px;
  text-align: left;
  margin-bottom: 25px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.pricing-table-title a {
  font-size: 0.6em;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  clear: both;
}
/** ========================
 * Contenedor
 ============================*/
.pricing-wrapper {
  max-width: 1360px;
  width: 100%;
  margin: 0px auto 0;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.pricing-table {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 384px;
  width: 100%;
  background: #fff;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
}

.pricing-table:hover {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  -o-transform: scale(1.06);
  transform: scale(1.06);
  transition: all 0.3s ease;
  border: 1px solid #00a67e;
}

.pricing-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #00a67e;
}

.pricing-table.recommended .pricing-title {
  background: #2db3cb;
}

.pricing-table.recommended .pricing-action {
  background: #2db3cb;
}

.pricing-table .price {
  /* background: #403e3d; */
  font-size: 2.4em;
  font-weight: 700;
  padding: 8px;
  /* text-shadow: 0 1px 1px rgba(0,0,0,0.4); */
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  /* identical to box height, or 188% */
  text-align: center;
  letter-spacing: -0.02em;
  /* Gray/900 */
  color: #101828;
}

.pricing-table .price a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #00d8a4 !important;
}
.disable {
  pointer-events: none;
  opacity: 0.4;
}
.table-list {
  background: #fff;
  color: #403d3a;
  padding: 10px;
  text-align: left;
  padding: 32px;
  padding-bottom: 0;
}

.table-list li {
  max-width: 255px;
  font-size: 1.4em;
  font-weight: 700;
  padding: 0px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray/500 */
  color: #667085;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  word-break: break-all;
}
/*#pricing .pricing-wrapper.clearfix{*/
/*    margin: 40px;*/
/*}*/
/*.table-list li:before {*/
/*    content: "\2713";*/
/*    font-family: 'FontAwesome';*/
/*    color: #3fab91;*/
/*    display: inline-block;*/
/*    position: relative;*/
/*    right: 5px;*/
/*    font-size: 16px;*/
/*}*/

.table-list li span {
  font-weight: 400;
  content: "\2713";
  color: #00d8a4;
}

.table-list li span.unlimited {
  color: #fff;
  background: #00d8a4;
  font-size: 0.9em;
  padding: 5px 7px;
  display: inline-block;
  -webkit-border-radius: 38px;
  -moz-border-radius: 38px;
  border-radius: 38px;
}

.table-list li:nth-child(2n) {
  /* background: #F0F0F0; */
}

.table-buy {
  /* background: #FFF; */
  /* padding: 15px; */
  /* text-align: left; */
  /* overflow: hidden; */
  margin-top: auto;
  flex-shrink: 0;
}

.table-buy p {
  float: left;
  color: #37353a;
  font-weight: 700;
  font-size: 2.4em;
}

.table-buy p sup {
  font-size: 0.5em;
  position: relative;
  left: 5px;
}

.table-buy .pricing-action {
  float: right;
  color: #fff;
  background: #00d8a4;
  padding: 10px 16px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 700;
  font-size: 1.4em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.table-buy .pricing-action:hover {
  background: #cf4f3e;
}

.recommended .table-buy .pricing-action:hover {
  background: #228799;
}

.table-buy {
  background: #f9fafb;
  padding: 20px 0px;
}

.table-buy button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 17px;
  gap: 10px;
  width: 171.13px;
  height: 48px;
  background: linear-gradient(90deg, #61d4a6 0%, #51b399 97.88%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 auto;
}

.price sup {
  display: block;
  width: 100%;
  position: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #667085;
}

.pricing-table svg {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 10px;
}
ul.table-list span {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  /* margin: 16px 0; */
  margin-bottom: 16px;
}

ul.table-list span svg {
  margin: 0;
  padding: 0;
}

.custom-btn a {
  background: none;
  outline: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.sectionOne-txt h2 {
  font-weight: 400;
}
.custom-btn {
  top: -140px !important;
  left: 120px !important;
}
.my-b {
  padding-bottom: 5vh;
}
.my-tvh {
  padding-top: 0vh;
}
.btn-auto {
  width: 100%;
  text-align: center;
  padding-top: 25px;
}

.line {
  width: 1px;
  height: 100%;
  background-color: #8792a2;
}
.rad video {
  border-radius: 0.75rem;
}
/** ================
 * Responsive
 ===================*/
@media (min-width: 64rem) {
  .sectionOne-img img {
    transform: inherit;
    -webkit-transform-origin-x: inherit;
  }
  .custom-btn {
    top: -240px !important;
    left: 120px !important;
  }
  .max-w-31 {
    max-width: none;
  }
}
@media (min-width: 80rem) {
  .sectionOne-img img {
    transform: scale(1.7);
    -webkit-transform-origin-x: 430px;
  }
  .max-w-31 {
    max-width: 30em;
  }
  .custom-btn {
    top: -150px !important;
    left: 140px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .pricing-wrapper {
    justify-content: center;
  }
  .pricing-table {
    width: 320px;
  }
  h3.pricing-title {
    font-size: 18px;
  }
  .pricing-table .price {
    font-size: 28px;
  }
  .price sup {
    font-size: 18px;
  }
  .table-list li {
    font-size: 15px;
  }
  .table-buy {
    padding: 15px 0px;
  }
  .table-buy button {
    padding: 10px 15px;
    gap: 10px;
    width: 152px;
    height: 40px;
    font-size: 14px;
  }
  ul.table-list {
    padding: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .pricing-wrapper {
    /*width: 768px;*/
  }

  /* .table-list li {
    font-size: 1.3em;
  } */

  .onas {
    margin: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-wrapper {
    /*width: 420px;*/
  }

  .pricing-table {
    display: block;
    float: none;
    margin: 0 0 20px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .pricing-wrapper {
    width: 300px;
  }
  .table-list li {
    max-width: 195px;
  }
  .custom-btn {
    top: -100px !important;
    left: 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .eu-flag {
    margin-right: auto !important;;
  }
}

.eu-flag {
  margin-left: auto;
}

.table-list span li {
  word-break: break-word;
}

.contact-form-loading, 
.contact-form-success {
  height: 425px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-form-info-title {
  margin-bottom: 32px;
}

.contact-form-info-subtitle {
  color: #697386;
  margin-bottom: 32px;
}

.contact-form-success button {
  margin-top: 32px;
}

.contact-form-success img {
  margin-bottom: 64px;
}

.funding-bar {
  margin-left: auto;
  margin-right: auto;
}