* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #4b565f;
  background-color: #fff;
}
ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
.hiddenn {
  display: none;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.show {
  display: block;
  visibility: visible;
  pointer-events: auto;
  transition: 0.3s all;
  animation: leaves 0.3s ease-in-out forwards;
}
@keyframes leaves {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media only screen and (max-width: 500px) {
  img {
    width: 100%;
  }
}
.man {
  visibility: visible;
  pointer-events: auto;
  transition: 0.3s all;
  animation: showBoxMan 0.3s ease-in-out forwards;
}
@keyframes showBoxMan {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.women {
  visibility: visible;
  pointer-events: auto;
  transition: 0.3s all;
  animation: showBoxWomen 0.3s ease-in-out forwards;
}
@keyframes showBoxWomen {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
input[type="radio"].custom-radio {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #00d8a4;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="radio"].custom-radio::before {
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #00d8a4;
  background-color: #fff;
}
input[type="radio"].custom-radio:checked::before {
  transform: scale(1);
}
input[type="radio"].custom-radio:checked:focus {
  background-color: #fff;
  border-color: initial;
}
input[type="radio"].custom-radio:focus {
  --tw-ring-offset-width: 0;
}
input[type="radio"].custom-radio:checked:hover {
  background-color: #fff;
  border-color: initial;
}
.dropdownCustom {
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
}
.order {
  padding: 32px 32px 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 302px;
  height: 436px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
}
.order-info {
  margin-top: 37px;
}
.order-detals h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  color: #404040;
}
.payment-detals.flex {
  margin-top: 64px;
}
.order-info p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #697386;
  text-transform: capitalize;
}
.order-info span {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #414141;
}
.order-pay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  max-width: 260px;
  width: 100%;
}
.subTotal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0px;
}

.subTotal p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
}

.sales {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0px;
  border-top: 1px solid #e8e8eb;
  border-bottom: 1px solid #e8e8eb;
}

.sales p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #697386;
}

.total {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0px;
}

.total p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
}
.powered {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 195px;
  column-gap: 10px;
  margin-top: 24px;
}
.finalTotal {
  width: 100%;
  padding: 32px 32px 14px 32px;
  background: #f9fafb;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  margin-top: 24px;
}

.finalTotal p:first-child {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  color: #404040;
}
.finalTotal p:last-child {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #4aa28a;
  padding-top: 14px;
}
.btnDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: linear-gradient(90deg, #61d4a6 0%, #51b399 97.88%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  cursor: pointer;
  margin-top: 24px;
}
.btnDiv button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 12px 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.StripeElement {
  box-sizing: border-box;
  margin: 10px 0;
  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripe-form {
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align-items: center;*/
  /* width: 30%; */
  /* border: 2px solid #32325d; */
  border-radius: 5px;
  /*background: #61dafb;*/
}

.submit-btn {
  margin: 10px 0;
  width: 50%;
  align-self: center;
  background: #32325d;
  padding: 10px 14px;
  color: white;
  border-radius: 5px;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 15px;
}

.form-row {
  display: flex;
  flex-direction: column;
}
.form-input {
  margin: 10px 0;
  border: none;
  outline: none;
  padding: 0 15px;
  font-size: 15px;
}

.card-errors {
  color: red;
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.stripe-form label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.stripe-form button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.DemoWrapper {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 3px solid #e6ebf1;
}

.stripe-form button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.stripe-form input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripe-form input::placeholder {
  color: #aab7c4;
}

.stripe-form input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.modelItem {
  max-width: 400px;
  min-width: 300px;
  transition: transform .2s;
}

.modelItem:hover {
  transform: scale(1.05);
}

.lineSeparator {
  width: 220px;
  position: relative;
  margin-top: 16px;
  height:1px;
  background-color:gray
}

h3.lectorVoiceTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  color: #404040;
}

h3.aspectRatioTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  color: #404040;
}

.payment-title {
  margin-right: 20px;
}